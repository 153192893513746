<template>
  <div id="edit-questionnaire-layout">
    <div v-if="questionnaire" id="edit-questionnaire" class="row ">

      <b-card class="col-md-12">
        <b-card-title>Επεξεργασία ερωτηματολογίου</b-card-title>

        <b-card-body>
          <div class="row">
            <div class="col-md-12">
              <label class="mt-2">
                Όνομα ερωτηματολογίου:
                <b-form-input v-model="questionnaire.name" style="width:500px;" class="mt-2"></b-form-input>
              </label>
            </div>
            <div class="col-md-12">


              <label class="mt-2">
                Ερωτηματολόγιο:
              </label>
              <div v-for="question in questionnaire.questionnaire.questions" :key="question.id" class="p-1 border-1">

                <div class="row">
                  <div class="col-md-3">
                    <span class="text-muted">Ερώτηση</span><br>
                    <strong>{{question.question}}</strong>
                  </div>
                  <div class="col-md-3">
                    <span class="text-muted">Είδος απάντησης</span><br>
                    <strong>{{questionTypes.find(x => x.id === question.type).label}}</strong>
                  </div>
                  <div class="col-md-3">
                    <span class="text-muted">Ενέργειες</span><br>
                    <b-button variant="warning" size="xs" @click="toggleUpdateQuestion(question.id)" > Επεξεργασία</b-button>
                    <b-button variant="danger" size="xs" class="ml-1" @click="deleteQuestion(question.id)"><i class="icon-trash"></i></b-button>
                  </div>
                </div>
                <hr>
              </div>

              <div class="row">
                <b-button variant="primary" size="xs" @click="toggleAddQuestion">Προσθήκη ερώτησης</b-button>
              </div>

              <div v-if="showAddQuestion" class="col-md-12">

                <div class="row mt-5 bg-light p-3 align-items-end">
                  <div class="col-md-4">
                    <label>Η ερώτησή σας</label>
                    <b-form-input v-model="addObject.question" placeholder="Συμπληρώστε την ερώτησή σας"></b-form-input>
                  </div>
                  <div class="col-md-4">
                    <label>Είδος απάντησης:</label>
                    <b-form-select v-model="addObject.type" label-for="category" >
                      <option v-for="type of questionTypes" :key="type.id" :value="type.id">{{type.label}}</option>
                    </b-form-select>
                  </div>

                  <div class="col-md-4">
                    <b-button variant="success" @click="addQuestion">Προσθήκη ερώτησης</b-button>
                  </div>
                </div>

              </div>

              <div v-if="showUpdateQuestion" class="col-md-12">

                <div class="row mt-5 bg-light p-3 align-items-end">
                  <div class="col-md-4">
                    <label>Η ερώτησή σας</label>
                    <b-form-input v-model="updateObject.question" placeholder="Συμπληρώστε την ερώτησή σας"></b-form-input>
                  </div>
                  <div class="col-md-4">
                    <label>Είδος απάντησης:</label>
                    <b-form-select v-model="updateObject.type" label-for="category" >
                      <option v-for="type of questionTypes" :key="type.id" :value="type.id">{{type.label}}</option>
                    </b-form-select>
                  </div>

                  <div class="col-md-4">
                    <b-button variant="success" @click="updateQuestion(updateObject.id)">Ενημέρωση ερώτησης</b-button>
                  </div>
                </div>

              </div>



            </div>
          </div>




          <div class="text-right">
            <save-button @click="updateQuestionnaire" :disabled="!hasChanged"></save-button>
          </div>


        </b-card-body>
      </b-card>
    </div>


  </div>

</template>
<style lang="scss">

#edit-questionnaire{

}
</style>
<script>
import Vue from "vue"
import {v4} from 'uuid';

export default {

  page: {
    title: 'Λογαριασμός',
  },

  data() {
    return {
      user: this.$store ? this.$store.state.auth.currentUser : {} || {},
      questionnaireId: this.$route.params.questionnaireId,
      dietMeals: [[[],[],[],[],[]],[[],[],[],[],[]],[[],[],[],[],[]],[[],[],[],[],[]],[[],[],[],[],[]], [[],[],[],[],[]],[[],[],[],[],[]]],
      questionnaire: null,
      questionnaires: this.$store ? (this.$store.state.auth.questionnaires || []) : [] || [],
      initialState: null,
      initialName: null,
      questionTypes: [
          {id: 'yes_no', label: 'Επιλογή (ναι/οχι)'},
        {id: 'text', label: 'Πεδίο κειμένου (μικρό)'},
        {id: 'long_text', label: 'Πεδίο κειμένου (μεγάλο)'}
      ],
      showAddQuestion: false,
      showUpdateQuestion: false,
      addObject: {
        type: 'yes_no',
        question: '',
      },
      updateObject: {}
    }
  },

  computed:{
    hasChanged(){
      return (this.initialState && this.initialName) ?
          (this.initialState !== JSON.stringify(this.questionnaire))
          || (this.questionnaire.name !== this.initialName) : false;
    }
  },

  async created() {
    if(this.questionnaires.length === 0){
      await this.$store.dispatch('auth/getPrintQuestionnaires');
    }
    this.initQuestionnaire();


  },

  methods:{

    initQuestionnaire(){
      this.questionnaire = JSON.parse(JSON.stringify(this.questionnaires.find(x => {return x.id === this.questionnaireId})));
      this.title = this.questionnaire.name;

      this.$store.commit('ui/setActiveItem', {
        value: this.title,
        index: 0
      })

      if(!this.questionnaire.questionnaire.questions){
        this.questionnaire.questionnaire = {
          questions: []
        }
      }
      this.initialState = JSON.stringify(this.questionnaire);
      this.initialName = this.questionnaire.name;


    },

    updateQuestionnaire(){
      this.$store.dispatch('auth/updateQuestionnaire', this.questionnaire).then( res => {
        this.initQuestionnaire();
        this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Το πρότυπο αποθηκεύτηκε επιτυχώς!'});
      }).catch( () => {
        this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η αποθήκευση απέτυχε. Παρακαλώ προσπαθήστε ξανά!'});
      })
    },

    addQuestion(){
      let question = {
        id: v4(),
        question: this.addObject.question,
        type: this.addObject.type
      }
      // add question to array
      this.questionnaire.questionnaire.questions.push(question);
      this.addObject =  {
        type: 'yes_no',
        question: '',
      };
      this.showAddQuestion = false;
    },

    toggleAddQuestion(){
      this.showUpdateQuestion = false;
      this.addObject =  {
        type: 'yes_no',
        question: '',
      };
      this.showAddQuestion = !this.showAddQuestion;
    },
    toggleUpdateQuestion(id){

      this.showAddQuestion = false;

      let question = this.questionnaire.questionnaire.questions.find(x => x.id === id);
      this.updateObject = {
        id: question.id,
        type: question.type,
        question: question.question
      }
      this.showUpdateQuestion = !this.showUpdateQuestion;
    },
    deleteQuestion(id){

      this.questionnaire.questionnaire.questions.splice(this.questionnaire.questionnaire.questions.findIndex(item => {
        return item.id === id;
      }), 1);
    },
    updateQuestion(id){

      for(let i =0; i <this.questionnaire.questionnaire.questions.length; i++){
        if(this.questionnaire.questionnaire.questions[i].id === id){

          Vue.set(this.questionnaire.questionnaire.questions, i, {id: id, type: this.updateObject.type, question: this.updateObject.question});
        }
      }
      this.showUpdateQuestion = false;
    }
  }

}
</script>
